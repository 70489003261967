<template>
    <div>
        <ts-page-title
            :title="$t('basicSalary.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('basicSalary.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <div class="tw-space-x-2">
                    <Button type="info" ghost @click="addNew">{{
                        $t('addNew')
                    }}</Button>
                    <Button
                        type="success"
                        @click.prevent="onShowExport"
                        :loading="exporting"
                    >
                        <i class="fas fa-file-excel"></i> {{ $t('export') }}
                    </Button>
                    <Button
                        type="primary"
                        @click.prevent="onShowImport"
                        :loading="exporting"
                    >
                        <i class="fas fa fa-upload"></i> {{ $t('import') }}
                    </Button>
                    <Checkbox v-model="history" history
                        >Include History
                    </Checkbox>
                </div>
                <div class="tw-justify-end tw-space-x-2">
                    <Input
                        v-model="search"
                        search
                        :placeholder="$t('basicSalary.search')"
                        :style="width"
                        @on-focus="() => (width = 'width: 500px')"
                        @on-blur="() => (width = 'width: 300px')"
                    />
                    <ts-button
                        v-tooltip="$t('filter')"
                        @click.prevent="visible = true"
                    >
                        <i class="fa fa-filter"></i>
                    </ts-button>
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                stripe
            >
                <template slot-scope="{ row }" slot="employee_name_kh">
                    {{
                        row.employee_profile
                            ? row.employee_profile.employee_name_kh
                            : ''
                    }}
                </template>
                <template slot-scope="{ row }" slot="employee_name_en">
                    {{
                        row.employee_profile
                            ? row.employee_profile.employee_name_en
                            : ''
                    }}
                </template>
                <template slot-scope="{ row }" slot="card_id">
                    {{
                        row.employee_profile ? row.employee_profile.card_id : ''
                    }}
                </template>
                <template slot-scope="{ row }" slot="position_id">
                    {{
                        row.employee_profile.logical_position
                            ? row.employee_profile.logical_position
                                  .position_name_en
                            : ''
                    }}
                </template>
                <template slot-scope="{ row }" slot="payment_type_id">
                    {{
                        row.employee_profile.payment_type
                            ? row.employee_profile.payment_type.payment_type
                            : ''
                    }}
                </template>
                <template slot-scope="{ row }" slot="payroll_visible">
                    <span>
                        <span
                            v-if="row.payroll_visible"
                            class="ios-checkmark-circle-outline badge bg-success"
                            >Yes</span
                        >
                        <span
                            v-else
                            class="ios-close-circle-outline badge bg-danger"
                            >NO</span
                        >
                    </span>
                </template>
                <template slot-scope="{ row }" slot="branch">
                    {{
                        row.employee_profile.branch
                            ? row.employee_profile.branch.branch_name_en
                            : ''
                    }}
                </template>
                <template slot-scope="{ row }" slot="salary">
                    <strong>{{ formatNumber(row.salary) }}</strong>
                </template>

                <template slot-scope="{ row }" slot="salary_after_probation">
                    <strong>{{
                        formatNumber(row.salary_after_probation)
                    }}</strong>
                </template>
                <template slot-scope="{ row }" slot="action">
                    <a
                        href="#"
                        class="text-primary ml-2"
                        @click.prevent="onEdit(row)"
                        v-tooltip="$t('edit')"
                    >
                        <Icon type="ios-create" size="20" />
                    </a>
                    <Poptip
                        confirm
                        :title="$t('areYouSure')"
                        @on-ok="onDelete(row)"
                        :transfer="true"
                        :ok-text="$t('yes')"
                        :cancel-text="$t('no')"
                    >
                        <a
                            class="ml-2 text-danger"
                            v-tooltip="$t('delete')"
                            :disabled="row._deleting"
                        >
                            <i
                                class="fas fa-circle-notch fa-spin"
                                v-if="row._deleting"
                            ></i>
                            <Icon type="ios-trash" size="20" v-else />
                        </a>
                    </Poptip>
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
        </ts-panel>
        <Drawer
            :title="$t('filter')"
            :closable="false"
            :value="visible"
            @on-close="() => (visible = false)"
            width="300px"
        >
            <div class="mb-3">
                <label class="form-col-label control-label">{{
                    $t('branchName')
                }}</label>
                <Select
                    v-model="branch_id"
                    multiple
                    :max-tag-count="1"
                    :filterable="true"
                    :filter-by-label="true"
                >
                    <Option
                        v-for="item in branches"
                        :value="item.branch_id"
                        :key="item.branch_id"
                        :label="item.branch_name_en"
                    >
                        {{ item.branch_name_en }}
                    </Option>
                </Select>
            </div>
        </Drawer>
        <Modal
            v-model="show_export_form"
            :centered="true"
            :closable="false"
            :zIndex="1020"
            :title="$t('export')"
            :okText="$t('yes')"
            :cancelText="$t('no')"
            @on-ok="onExport"
            @on-cancel="clearEdit"
            :confirmLoading="exporting"
            width="450px"
        >
            <ts-loading-banner
                :loading="export_resource_loading"
                class="ts-space-y-2"
            >
                <div class="mb-3">
                    <label class="form-col-label control-label">{{
                        $t('branchName')
                    }}</label>
                    <Select
                        v-model="model.branch_id"
                        multiple
                        :max-tag-count="1"
                        :filterable="true"
                        :filter-by-label="true"
                    >
                        <Option
                            v-for="item in branches"
                            :value="item.branch_id"
                            :key="item.branch_id"
                            :label="item.branch_name_en"
                        >
                            {{ item.branch_name_en }}
                        </Option>
                    </Select>
                </div>
                <div class="mb-3">
                    <RadioGroup v-model="model.id_type">
                        <Radio
                            class="mt-1"
                            :label="c.value"
                            border
                            v-for="(c, index) in calculateTypes"
                            :key="index"
                        >
                            {{ c.label }}
                        </Radio>
                    </RadioGroup>
                </div>
                <div class="mb-3" v-if="model.id_type == 1">
                    <div class="col-md-12">
                        <label>{{
                            $t('reportPayrollDetail.orgChartStructure')
                        }}</label>
                        <Select
                            v-model="model.org_chart_structure_id"
                            filterable
                            :loading="loading"
                            class="from-label"
                            :placeholder="$t('all')"
                            :class="{
                                'is-invalid': errors.has(
                                    'org_chart_structure_id'
                                )
                            }"
                            :clearable="true"
                            @on-change="onOrgChange"
                        >
                            <Option
                                v-for="(org, index) in orgStructure"
                                :value="org.org_chart_structure_id"
                                :key="index"
                            >
                                {{ org.org_chart_structure }}
                            </Option>
                        </Select>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('org_chart_structure_id')"
                        >
                            {{ errors.first('org_chart_structure_id') }}
                        </div>
                    </div>
                </div>
                <div class="mb-3" v-if="model.id_type == 2">
                    <div class="col-md-12">
                        <label>{{ $t('reportPayrollDetail.shiftName') }}</label>
                        <Select
                            v-model="model.shift_id"
                            filterable
                            :loading="loading"
                            class="from-label"
                            multiple
                            :max-tag-count="2"
                            :placeholder="$t('all')"
                            @on-change="onShiftChange"
                        >
                            <Option
                                v-for="(s, index) in shift"
                                :value="s.shift_id"
                                :key="index"
                            >
                                {{ s.shift_name + '-' + s.shift_type }}
                            </Option>
                        </Select>
                        <div
                            class="invalid-feedback"
                            v-if="errors.has('shift_id')"
                        >
                            {{ errors.first('shift_id') }}
                        </div>
                    </div>
                </div>
                <div class="row tw-mb-6">
                    <div class="col-sm-12">
                        <label>{{ $t('payrollCalculation.employee') }}</label>
                        <div>
                            <Select
                                v-model="model.employee_id"
                                filterable
                                remote
                                :remote-method="searchEmployee"
                                :loading="loading"
                                class="from-label"
                                multiple
                                :max-tag-count="2"
                                :placeholder="$t('all')"
                            >
                                <Option
                                    v-for="(emp, index) in employeeProfile"
                                    :value="emp.employee_id"
                                    :key="index"
                                >
                                    {{
                                        emp.card_id +
                                        '-' +
                                        emp.employee_name_kh +
                                        '-' +
                                        emp.employee_name_en
                                    }}
                                </Option>
                            </Select>
                            <div
                                class="invalid-feedback"
                                v-if="errors.has('employee_id')"
                            >
                                {{ errors.first('employee_id') }}
                            </div>
                        </div>
                    </div>
                </div>
            </ts-loading-banner>
        </Modal>
        <Modal
            :title="$t('import')"
            v-model="show_import_form"
            :visible="show_import_form"
            footer-hide
            width="350px"
            :centered="true"
        >
            <ImportProductPrice @cancel="onImportCancel()" />
        </Modal>
        <Modal
            v-model="showForm"
            draggable
            sticky
            scrollable
            :mask="false"
            :footer-hide="true"
            :z-index="1022"
            :title="$t('basicSalary.basicSalary')"
        >
            <from-action
                ref="form_action"
                @cancel="clearEdit"
                @fetchData="fetchData"
            />
        </Modal>
    </div>
</template>
<script>
import FromAction from './form.vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import { debounce } from 'lodash'
import * as FileDownload from 'downloadjs'
import { trim } from 'lodash'
import { Errors } from 'form-backend-validation'
import ImportProductPrice from './importProductPrice.vue'

export default {
    name: 'basic-salary',
    components: {
        FromAction,
        ImportProductPrice
    },
    data () {
        return {
            show_export_form: false,
            show_import_form: false,
            exporting: false,
            importing: false,
            loading: false,
            export_resource_loading: false,
            showForm: false,
            visible: false,
            branch_id: [],
            width: 'width: 300px',
            errors: new Errors(),
            branches: [],
            orgStructure: [],
            shift: [],
            employeeProfile: [],
            model: {
                branch_id: [],
                id_type: 1,
                org_chart_structure_id: null,
                shift_id: [],
                employee_id: []
            },
            calculateTypes: [
                {
                    value: 1,
                    label: 'Organizational Structure'
                },
                {
                    value: 2,
                    label: 'Shift'
                }
            ]
        }
    },
    computed: {
        ...mapGetters(['formatNumber']),
        ...mapState('humanResource/basicSalary', ['resources', 'pagination']),
        search: {
            get () {
                return this.$store.state.humanResource.basicSalary.search
            },
            set (newValue) {
                this.$store.commit(
                    'humanResource/basicSalary/SET_SEARCH',
                    newValue
                )
                this.$store.commit(
                    'humanResource/basicSalary/RESET_CURRENT_PAGE'
                )
            }
        },
        history: {
            get () {
                return this.$store.state.humanResource.basicSalary.history
            },
            set (newValue) {
                this.$store.commit(
                    'humanResource/basicSalary/SET_HISTORY',
                    newValue
                )
            }
        },
        columns () {
            return [
                {
                    title: this.$t('basicSalary.branch'),
                    slot: 'branch',
                    width: 140,
                    sortable: true
                },
                {
                    title: this.$t('basicSalary.cardID'),
                    slot: 'card_id',
                    width: 140,
                    sortable: true
                },
                {
                    title: this.$t('basicSalary.nameKh'),
                    slot: 'employee_name_kh',
                    width: 180,
                    sortable: true
                },
                {
                    title: this.$t('basicSalary.nameEN'),
                    slot: 'employee_name_en',
                    width: 180,
                    sortable: true
                },
                {
                    title: this.$t('basicSalary.position'),
                    slot: 'position_id',
                    width: 180,
                    sortable: true
                },
                {
                    title: this.$t('basicSalary.paymentType'),
                    width: 150,
                    slot: 'payment_type_id',
                    sortable: true,
                    align: 'center'
                },
                {
                    title: this.$t('basicSalary.salary'),
                    slot: 'salary',
                    align: 'center',
                    width: 150,
                    sortable: true
                },
                {
                    title: this.$t('basicSalary.salaryAfterProbation'),
                    slot: 'salary_after_probation',
                    align: 'center',
                    width: 220,
                    sortable: true
                },
                {
                    title: this.$t('basicSalary.payrollVisible'),
                    key: 'payroll_visible',
                    slot: 'payroll_visible',
                    sortable: true,
                    align: 'center',
                    width: 170,
                    filters: [
                        {
                            label: 'Yes',
                            value: false
                        },
                        {
                            label: 'No',
                            value: true
                        }
                    ],
                    filterMultiple: false,
                    filterRemote (value) {
                        this.fetchData({
                            filter: {
                                payroll_visible: value
                            }
                        })
                    }
                },
                {
                    title: this.$t('basicSalary.effectiveDate'),
                    key: 'effective_date',
                    align: 'center',
                    width: 180,
                    sortable: true
                },
                {
                    title: this.$t('basicSalary.createDate'),
                    key: 'created_date',
                    align: 'center',
                    width: 170,
                    sortable: true
                },
                {
                    title: this.$t('actions'),
                    key: 'action',
                    slot: 'action',
                    fixed: 'right',
                    align: 'center',
                    width: 170
                }
            ]
        }
    },
    methods: {
        ...mapActions('humanResource/basicSalary', ['getFormViewData']),
        async fetchExportResource (attributes) {
            this.export_resource_loading = true
            let response = await this.getFormViewData({
                params: attributes
            })
            if (attributes.fnName) {
                attributes.fnName.split(',').forEach(fn => {
                    this[fn] = response[fn]
                })
            }
            this.export_resource_loading = false
        },
        async onOrgChange (value) {
            this.model.employee_id = []
            this.fetchExportResource({
                fnName: 'employeeProfile',
                org_chart_structure_id: value
            })
        },
        async onShiftChange (value) {
            this.model.employee_id = []
            this.fetchExportResource({
                fnName: 'employeeProfile',
                shift_id: value
            })
        },
        searchEmployee: debounce(async function (query) {
            if (this.employeeProfile.length > 0) {
                var avoidSearch = this.employeeProfile.find(
                    emp =>
                        emp.card_id +
                            '-' +
                            emp.employee_name_kh +
                            '-' +
                            emp.employee_name_en ==
                        query.trim()
                )
                if (avoidSearch) return
            }
            this.fetchExportResource({
                fnName: 'employeeProfile',
                emp_search: query,
                org_chart_structure_id: this.model.org_chart_structure_id,
                shift_id: this.model.shift_id,
                branch_id: this.model.branch_id
            })
        }, 500),
        onExport () {
            this.exporting = true
            this.errors = new Errors()
            this.$store
                .dispatch('humanResource/basicSalary/exportExcel', this.model)
                .then(response => {
                    let filename = trim(
                        response.headers['content-disposition']
                            .substring(
                                response.headers['content-disposition'].indexOf(
                                    'filename'
                                )
                            )
                            .replace('filename=', ''),
                        '"'
                    )
                    FileDownload(response.data, filename)
                    this.show_export_form = false
                    this.clearInput()
                })
                .catch(err => {
                    let error = JSON.parse(
                        String.fromCharCode.apply(null, new Uint8Array(err))
                    )
                    this.$toasted.error(error.message)
                    this.errors = new Errors(error.errors)
                })
                .finally(() => {
                    this.exporting = false
                })
        },
        onShowExport () {
            this.show_export_form = true
            this.fetchBranch()
            this.fetchExportResource({
                fnName: 'orgStructure,shift,employeeProfile'
            })
        },
        onShowImport () {
            this.importing = true
            this.show_import_form = true
        },
        onImportCancel () {
            this.show_import_form = false
            this.importing = false
            this.fetchData()
        },
        fetchData (attributes) {
            this.loading = true
            this.fetchBranch()
            this.$store
                .dispatch(
                    'humanResource/basicSalary/fetch',
                    Object.assign(
                        {
                            branch_id: this.branch_id,
                            page: this.pagination.currentPage
                        },
                        { ...attributes }
                    )
                )
                .catch(error => {
                    this.loading = false
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        addNew () {
            this.showForm = true
            this.$store.commit('humanResource/basicSalary/SET_EDIT_DATA', {})
            this.$refs.form_action.clearInput()
            this.$refs.form_action.fetchResource()
        },
        onEdit (record) {
            this.showForm = true
            this.$store.commit(
                'humanResource/basicSalary/SET_EDIT_DATA',
                record
            )
            this.$refs.form_action.setEditData()
            this.$refs.form_action.fetchResource()
            this.$refs.form_action.clearInput()
        },
        async onDelete (record) {
            record._deleting = true
            this.$store
                .dispatch('humanResource/basicSalary/destroy', record.id)
                .then(response => {
                    this.fetchData()
                    record._deleting = false
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                })
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                    record._deleting = false
                })
        },
        clearEdit () {
            this.showForm = false
            this.$refs.form_action.clearInput(),
                this.$store.commit(
                    'humanResource/basicSalary/SET_EDIT_DATA',
                    {}
                ),
                this.$refs.form_action.setEditData()
        },
        fetchBranch () {
            this.loading = true
            this.$store
                .dispatch('auth/user/getBranchAssigned')
                .then(response => {
                    this.branches = response.data
                })
                .catch(error => {
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'BASICS SALARY',
                desc: not.text
            })
        }
    },
    watch: {
        search: debounce(function (search) {
            this.fetchData({ search })
        }, 500),
        history: debounce(function (history) {
            this.fetchData({ history })
        }, 500),
        branch_id: debounce(function (value) {
            this.fetchData({ branch_id: value })
        }, 500),
        'model.id_type': function (value) {
            if (value == 1) {
                this.model.shift_id = null
            } else {
                this.model.org_chart_structure_id = null
            }
        }
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.fetchData()
        })
    }
}
</script>
