<template>
    <div>
        <ts-loading-banner :loading="loading">
            <div class="row tw-mt-3">
                <div class="col-md-12">
                    <label
                        for="file"
                        class="tw-border tw-p-2 tw-rounded-md tw-cursor-pointer"
                        >Browse File</label
                    >
                    <input
                        type="file"
                        ref="file"
                        id="file"
                        class="tw-hidden"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        @change="onFileChange"
                    />
                    <div class="validate" v-if="errors.has('file')">
                        {{ errors.first('file') }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    {{ display_name }}
                </div>
            </div>
            <br />
            <div class="row">
                <div class="col-md-12 tw-space-x-3 tw-flex tw-justify-end">
                    <ts-button @click="$emit('cancel')">{{
                        $t('cancel')
                    }}</ts-button>
                    <ts-button color="primary" @click="onImport">{{
                        $t('import')
                    }}</ts-button>
                </div>
            </div>
        </ts-loading-banner>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
export default {
    name: 'importFile',
    data () {
        return {
            loading: false,
            display_name: null,
            errors: new Errors(),
            file: undefined
        }
    },
    created () {},
    methods: {
        onFileChange (e) {
            this.display_name = null
            this.file = e.target.files[0]
            this.display_name = this.file.name
        },
        onImport () {
            this.loading = true
            this.errors = new Errors()

            let formData = new FormData()
            formData.append('file', this.file)

            this.$store
                .dispatch('humanResource/basicSalary/importExcel', formData)
                .then(response => {
                    this.$toasted.success(response.message)
                    this.$emit('cancel')
                    this.clearInput()
                })
                .catch(error => {
                    this.$toasted.error(error.message)
                    this.errors = new Errors(error.errors)
                })
                .finally(() => {
                    this.loading = false
                })
        },
        clearInput () {
            this.$refs.file.value = null
            this.file = undefined
        }
    }
}
</script>
